import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import './style/homeblogpage.css'

const client = new ApolloClient({
  uri: "https://techmatterglobal.com/blog/graphql",
  cache: new InMemoryCache(),
});

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const BlogDetail = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: gql`
          {
            posts(first: 3) {
              nodes {
                id
                title
                slug
                content
                date
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        const formattedPosts = result.data.posts.nodes.map((post) => ({
          ...post,
          date: formatDate(post.date),
        }));
        setPosts(formattedPosts);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="pd-top-20 pd-bottom-20">
        <div className="blogCardContainer">
          {posts.map((post) => (
            <div className="card blogcard" key={post.id}>
              {post.featuredImage && post.featuredImage.node && (
                <img
                  src={post.featuredImage.node.sourceUrl}
                  className="card-img-top"
                  alt="Blog Featured Image"
                />
              )}
              <div className="card-body">
                <h5 className="card-title">
                  <Link to={`/blogs/${post.slug}`} onClick={scrollToTop}>{post.title}</Link>
                </h5>
                <p
                  className="card-text"
                  style={{
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {post.content.replace(/<[^>]+>/g, "")}
                </p>
                <p className="card-text">
                  <small className="text-muted">{post.date}</small>
                </p>
              </div>
            </div>
          ))}
        </div>
        {loading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default BlogDetail;
