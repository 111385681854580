import { Route, Routes, Navigate } from "react-router-dom";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import HomeOne from "./pages/HomeOne";
import Service from "./pages/Service";
import ManagedITService from "./pages/ManagedITService";
import RevenueCycleManagement from "./pages/RevenueCycleManagement";
import HealthcareStaffAugmentation from "./pages/HealthcareStaffAugmentation";
import EnterpriseIT from "./pages/EnterpriseIT";
import Ehr from "./pages/Ehr";
import Marketing from "./pages/Marketing";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./pages/404";
import BlogPostDetail from "./components/blogs/BlogPostDetail";
import CategoryPage from './components/blogs/CategoryPage';
import AllCategories from "./pages/AllCategories";
// import Forms from "./pages/Forms";

const redirects = {
  "/blog/": "/blogs/",
  "/what-is-a-favicon-in-a-website/": "/blogs/what-is-a-favicon-in-a-website/",
  "/introduction-to-remote-management/":
    "/blogs/introduction-to-remote-management/",
  "/ai-ethics-innovation-responsibility/":
    "/blogs/ai-ethics-innovation-responsibility/",
  "/roles-of-it-infrastructure-services/":
    "/blogs/roles-of-it-infrastructure-services/",
  "/an-overview-of-shared-service/": "/blogs/an-overview-of-shared-service/",
  "/what-are-enterprise-software-applications/":
    "/blogs/what-are-enterprise-software-applications/",
  "/what-is-a-wildcard-redirect/": "/blogs/what-is-a-wildcard-redirect/",
  "/what-are-network-simulation-tools/":
    "/blogs/what-are-network-simulation-tools/",
  "/an-overview-of-cloud-computing/": "/blogs/an-overview-of-cloud-computing/",
  "/difference-between-routers-and-switches/":
    "/blogs/difference-between-routers-and-switches/",
  "/requirements-for-mobile-app-development/":
    "/blogs/requirements-for-mobile-app-development/",
  "/what-is-managed-internet-access/":
    "/blogs/what-is-managed-internet-access/",
  "/advantages-of-outsourced-network-management/":
    "/blogs/advantages-of-outsourced-network-management/",
  "/the-power-of-ai-in-marketing/": "/blogs/the-power-of-ai-in-marketing/",
  "/benefits-of-itsm-for-global-businesses/":
    "/blogs/benefits-of-itsm-for-global-businesses/",
  "/what-does-network-access-control-do/":
    "/blogs/what-does-network-access-control-do/",
  "/an-overview-of-managed-it-service/":
    "/blogs/an-overview-of-managed-it-service/",
  "/checklist-for-network-security-audit-2024/":
    "/blogs/checklist-for-network-security-audit-2024/",
  "/what-is-network-stack-in-bios/": "/blogs/what-is-network-stack-in-bios/",
  "/what-is-a-network-prefix-length/":
    "/blogs/what-is-a-network-prefix-length/",
  "/outsource-managed-services-for-business-growth/":
    "/blogs/outsource-managed-services-for-business-growth/",
  "/it-security-checklist-for-remote-employees/":
    "/blogs/it-security-checklist-for-remote-employees/",
  "/shared-hosting-vs-managed-wordpress-hosting/":
    "/blogs/shared-hosting-vs-managed-wordpress-hosting/",
  "/what-is-ibm-rational-functional-tester/":
    "/blogs/what-is-ibm-rational-functional-tester/",
  "/what-is-application-software-with-example/":
    "/blogs/what-is-application-software-with-example/",
  "/software-development-best-practices-checklist-2023/":
    "/blogs/software-development-best-practices-checklist-2023/",
  "/what-can-you-do-with-javascript/":
    "/blogs/what-can-you-do-with-javascript/",
  "/guide-to-software-development-process-2023/":
    "/blogs/guide-to-software-development-process-2023/",
  "/next-gen-firewall-vs-traditional-firewall/":
    "/blogs/next-gen-firewall-vs-traditional-firewall/",
  "/best-software-development-tools-in-2023/":
    "/blogs/best-software-development-tools-in-2023/",
  "/difference-between-managed-and-unmanaged-switches/":
    "/blogs/difference-between-managed-and-unmanaged-switches/",
  "/why-it-infrastructure-management-is-important/":
    "/blogs/why-it-infrastructure-management-is-important/",
  "/navigating-the-complexities-of-it-strategy/":
    "/blogs/navigating-the-complexities-of-it-strategy/",
  "/what-is-server-monitoring-and-management/":
    "/blogs/what-is-server-monitoring-and-management/",
  "/differences-between-managed-services-and-outsourcing/":
    "/blogs/differences-between-managed-services-and-outsourcing/",
  "/8-free-remote-desktop-softwares-in-2022/":
    "/blogs/8-free-remote-desktop-softwares-in-2022/",
  "/difference-between-enterprise-apps-and-consumer-apps/":
    "/blogs/difference-between-enterprise-apps-and-consumer-apps/",
  "/best-programming-languages-for-ai-development-2023/":
    "/blogs/best-programming-languages-for-ai-development-2023/",
  "/offshore-development-center-what-is-an-odc/":
    "/blogs/offshore-development-center-what-is-an-odc/",
  "/dns-troubleshoot-and-security-basics-of-dns/":
    "/blogs/dns-troubleshoot-and-security-basics-of-dns/",
  "/the-future-of-ai-powered-content-creation/":
    "/blogs/the-future-of-ai-powered-content-creation/",
  "/boost-sales-with-personalized-marketing-through-ai/":
    "/blogs/boost-sales-with-personalized-marketing-through-ai/",
  "/faqs/": "/",
  "/how-to-hire-a-professional-it-company/":
    "/blogs/how-to-hire-a-professional-it-company",
  "/the-importance-of-data-quality-in-ai/":
    "/blogs/the-importance-of-data-quality-in-ai",
  "/how-managed-services-can-improve-your-companys-cybersecurity/":
    "/blogs/how-managed-services-can-improve-your-companys-cybersecurity",
  "/what-are-the-different-components-of-it-infrastructure/":
    "/blogs/what-are-the-different-components-of-it-infrastructure",
  "/discover-the-benefits-of-managed-security-services-mssp/":
    "/blogs/discover-the-benefits-of-managed-security-services-mssp",
  "/what-are-the-major-features-of-react-js/":
    "/blogs/what-are-the-major-features-of-react-js",
  "/uncovering-the-benefits-of-pay-per-click-advertising/":
    "/blogs/uncovering-the-benefits-of-pay-per-click-advertising",
  "/what-are-the-main-features-of-microsoft-teams/":
    "/blogs/what-are-the-main-features-of-microsoft-teams",
  "/what-is-difference-between-oracle-12c-and-19c/":
    "/blogs/what-is-difference-between-oracle-12c-and-19c",
  "/maximize-productivity-and-efficiency-with-a-managed-help-desk/":
    "/blogs/maximize-productivity-and-efficiency-with-a-managed-help-desk",
  "/managed-cloud-services-how-to-improve-your-business-operations/":
    "/blogs/managed-cloud-services-how-to-improve-your-business-operations",
  "/ai-in-seo-what-is-the-future-of-seo/":
    "/blogs/ai-in-seo-what-is-the-future-of-seo",
  "/top-5-managed-services-trends-to-watch-in-2023/":
    "/blogs/top-5-managed-services-trends-to-watch-in-2023",
  "/how-to-connect-a-wireless-mouse-to-a-chromebook/":
    "/blogs/how-to-connect-a-wireless-mouse-to-a-chromebook",
  "/sap-erp-vs-sap-ecc-vs-s-4-hana/": "/blogs/sap-erp-vs-sap-ecc-vs-s-4-hana",
  "/modular-data-centers-and-their-benefits-for-it-infrastructure/":
    "/blogs/modular-data-centers-and-their-benefits-for-it-infrastructure",
  "/why-do-we-use-agile-methodology-in-software-development/":
    "/blogs/why-do-we-use-agile-methodology-in-software-development",
  "/what-are-the-different-types-of-data-center-design/":
    "/blogs/what-are-the-different-types-of-data-center-design",
  "/what-is-a-jump-server-and-how-does-it-work/":
    "/blogs/what-is-a-jump-server-and-how-does-it-work",
  "/what-are-the-differences-between-layer-2-and-layer-3-switches/":
    "/blogs/what-are-the-differences-between-layer-2-and-layer-3-switches",
  "/iot-network-security-the-essential-guide-for-protecting-your-iot-network/":
    "/blogs/iot-network-security-the-essential-guide-for-protecting-your-iot-network",
  "/unlocking-the-power-of-microsoft-intune-6-benefits-for-modern-businesses/":
    "/blogs/unlocking-the-power-of-microsoft-intune-6-benefits-for-modern-businesses",
  "/how-managed-services-can-help-your-business-stay-compliant/":
    "/blogs/how-managed-services-can-help-your-business-stay-compliant",
  "/services/": "/service/",
  "/about-us/": "/about/",
  "/content-writing-service/": "/marketing/",
  "/digital-marketing-service/": "/marketing/",
  "/medical-virtual-assistant/": "/revenue-cycle-management/",
  "/web-development/": "/marketing/",
  "/medical-billing/": "/revenue-cycle-management/",
  "/it-infrastructure/": "/enterprise-it/",
  "/software-development/": "/marketing/",
  "/managed-it-helpdesk/": "/enterprise-it/",
  "/pay-per-click-marketing/": "/marketing/",
  "/seo-service/": "/marketing/",
  "/denial-management/": "/revenue-cycle-management/",
  "/press-release/": "/about/",
  "/ar-followups/": "/revenue-cycle-management/",
  "/credentialing-services/": "/revenue-cycle-management/",
  "/it-solutions/": "/managed-it-services/",
  "/managed-security-services/": "/enterprise-it/",
  "/dme-billing-services/": "/revenue-cycle-management/",
  "/credentialing/": "/revenue-cycle-management/",
  "/rcm-solutions/": "/revenue-cycle-management/",
  "/medical-billing-services/": "/revenue-cycle-management/",
  "/medical-coding/": "/revenue-cycle-management/",
  "/mission-vision/": "/about/",
  "/awards/": "/about/",
  "/testimonials/": "/about/",
  "/managed-services-customer-experience/": "/blogs/managed-services-customer-experience/",
  "/future-of-cybersecurity-what-to-expect/": "/blogs/future-of-cybersecurity-what-to-expect/",
  "/what-is-revenue-cycle-management-rcm-in-healthcare/": "/blogs/what-is-revenue-cycle-management-rcm-in-healthcare/",
  "/how-ai-is-changing-the-face-of-marketing/": "/blogs/how-ai-is-changing-the-face-of-marketing/",
  "/a-comprehensive-guide-to-understanding-blockchain-protocols-and-their-types/": "/blogs/a-comprehensive-guide-to-understanding-blockchain-protocols-and-their-types/",
  "/what-are-consumer-apps-with-examples/": "/blogs/what-are-consumer-apps-with-examples/",
  "/offshore-and-nearshore-outsourcing/": "/blogs/offshore-and-nearshore-outsourcing/",
  "/list-of-best-frameworks-for-software-development-2023/": "/blogs/list-of-best-frameworks-for-software-development-2023/",
  "/a-guide-to-choosing-the-right-programming-language/": "/blogs/a-guide-to-choosing-the-right-programming-language/",
  "/features-and-limitations-of-firewalls/": "/blogs/features-and-limitations-of-firewalls/",
  "/how-to-choose-a-ddos-mitigation-provider/": "/blogs/how-to-choose-a-ddos-mitigation-provider/",
  "/tips-to-improve-your-home-or-office-network-security/": "/blogs/tips-to-improve-your-home-or-office-network-security/",
  "/it-strategy-growth-tech-business-needs/": "/blogs/it-strategy-growth-tech-business-needs/",
  "/top-5-it-trends-for-2023/": "/blogs/top-5-it-trends-for-2023/",
  "/learn-about-data-backups-disaster-recovery/": "/blogs/learn-about-data-backups-disaster-recovery/",
  "/why-companies-buy-mssp/": "/blogs/why-companies-buy-mssp/",
  "/tips-for-effective-managed-security/": "/blogs/tips-for-effective-managed-security/",
  "/a-comprehensive-guide-to-iot-or-internet-of-things/": "/blogs/a-comprehensive-guide-to-iot-or-internet-of-things/",
  "/all-you-need-to-know-about-managed-service/": "/blogs/all-you-need-to-know-about-managed-service/",
  "/what-is-project-estimation-in-software-engineering/": "/blogs/what-is-project-estimation-in-software-engineering/",
  "/steps-in-revenue-cycle-management-rcm-guide-2024/": "/blogs/steps-in-revenue-cycle-management-rcm-guide-2024/",
  "/the-future-of-ai-predictions-and-possibilities/": "/blogs/the-future-of-ai-predictions-and-possibilities/",
  "/the-top-10-tech-trends-to-watch-in-2024/": "/blogs/the-top-10-tech-trends-to-watch-in-2024/",
  "/the-top-10-ai-powered-startups-to-watch/": "/blogs/the-top-10-ai-powered-startups-to-watch/",
  "/how-chatbots-can-improve-employee-productivity/": "/blogs/how-chatbots-can-improve-employee-productivity/",
  "/seo-vs-ppc-key-differences/": "/blogs/seo-vs-ppc-key-differences/",
  "/what-is-staff-augmentation-in-digital-healthcare/": "/blogs/what-is-staff-augmentation-in-digital-healthcare/",
  "/digital-marketing-trends/": "/blogs/digital-marketing-trends/",
  "/healthcare-revenue-cycle-management-strategies/": "/blogs/healthcare-revenue-cycle-management-strategies/",
  "/revenue-cycle-automation-in-healthcare/": "/blogs/revenue-cycle-automation-in-healthcare/",
  "/social-media-marketing-vs-content-marketing/": "/blogs/social-media-marketing-vs-content-marketing/",
  "/how-to-choose-a-managed-service-provider/": "/blogs/how-to-choose-a-managed-service-provider/",
  "/iot-digital-transformation/": "/blogs/iot-digital-transformation/",
  "/outsourcing-vs-in-house-software-development/": "/blogs/outsourcing-vs-in-house-software-development/",
  "/future-of-blockchain-app-development/": "/blogs/future-of-blockchain-app-development/",
  "/medical-billing-vs-revenue-cycle-management": "/blogs/medical-billing-vs-revenue-cycle-management/",
  "/what-is-the-difference-between-emr-and-ehr/": "/blogs/what-is-the-difference-between-emr-and-ehr/",
  "/managed-it-services-pricing-guide-for-small-business/": "/blogs/managed-it-services-pricing-guide-for-small-business/",
  "/top-digital-marketing-analytics-tools/": "/blogs/top-digital-marketing-analytics-tools/",
  "/guide-to-healthcare-it-managed-services/": "/blogs/guide-to-healthcare-it-managed-services/",
  "/top-marketing-automation-best-practices/": "/blogs/top-marketing-automation-best-practices/",
  "/top-affiliate-marketing-trends/": "/blogs/top-affiliate-marketing-trends/",
  "/advantages-of-managed-it-services/": "/blogs/advantages-of-managed-it-services/",
  "/generative-engine-optimization-vs-search-engine-optimization/": "/blogs/generative-engine-optimization-vs-search-engine-optimization/",
  "/cost-of-cybersecurity-for-small-businesses/": "/blogs/cost-of-cybersecurity-for-small-businesses/",
  "/top-types-of-healthcare-software/": "/blogs/top-types-of-healthcare-software/",
  "/visual-content-marketing-on-social-media/": "/blogs/visual-content-marketing-on-social-media/",
  "/ehr-or-psychiatry-practices/": "/blogs/ehr-or-psychiatry-practices/",
  "/proven-local-marketing-ideas/": "/blogs/proven-local-marketing-ideas/",
  "/common-digital-marketing-mistakes/": "/blogs/common-digital-marketing-mistakes/",
  "/artificial-intelligence-vs-human-intelligence/": "/blogs/artificial-intelligence-vs-human-intelligence/",
  "/ehr-vs-papers-records/": "/blogs/ehr-vs-papers-records/",
  "/react-native-vs-native/": "/blogs/react-native-vs-native/",
  "/top-influencer-marketing-trends/": "/blogs/top-influencer-marketing-trends/",
  "/ipa-vs-rpa/": "/blogs/ipa-vs-rpa/",
  "/medical-practice-management-software/": "/blogs/medical-practice-management-software/",
  "/what-is-word-processing-software/": "/blogs/what-is-word-processing-software/",
  "/expense-management-software-for-small-businesses/": "/blogs/expense-management-software-for-small-businesses/",
  "/top-features-in-podiatry-ehr-software/": "/blogs/top-features-in-podiatry-ehr-software/",
  "/top-b2b-marketing-trends/": "/blogs/top-b2b-marketing-trends/",
  "/future-of-healthcare-ai-driven-ehr/": "/blogs/future-of-healthcare-ai-driven-ehr/",
  "/contact-us/": "/contact/",
};

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);



  return (
    <>
      <Routes trailingSlash="force">
        {Object.entries(redirects).map(([from, to]) => (
          <Route key={from} path={from} element={<Navigate to={to} />} />
        ))}
        <Route path="/blogs/:slug" element={<BlogPostDetail />} />
        {/* <Route path="/forms" element={<Forms />} /> */}
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />
        <Route exact path="*" element={<PageNotFound />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/service" element={<Service />} />
        <Route
          exact
          path="/managed-it-services"
          element={<ManagedITService />}
        />
        <Route
          exact
          path="/revenue-cycle-management"
          element={<RevenueCycleManagement />}
        />
        <Route
          exact
          path="/healthcare-staff-augmentation"
          element={<HealthcareStaffAugmentation />}
        />
        <Route exact path="/electronic-health-record" element={<Ehr />} />
        <Route exact path="/enterprise-it" element={<EnterpriseIT />} />
        <Route exact path="/marketing" element={<Marketing />} />
        <Route path="/blogs/category" element={<AllCategories />} />
        <Route path="/blogs/category/:slug" element={<CategoryPage />} />
      </Routes>
      <ScrollToTop smooth color="#ffffff" />
    </>
  );
}

export default App;

// const location = useLocation();

// // Check if the current path ends with a trailing slash
// const hasTrailingSlash = location.pathname.endsWith("/");

// // If the path has a trailing slash, redirect to the path without the trailing slash
// if (hasTrailingSlash && location.pathname !== "/") {
//   return <Navigate to={location.pathname.slice(0, -1)} />;
// useLocation, Navigate, Form
// }