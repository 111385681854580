import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";


const ContactAreaOne = () => {

  const environment = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA!");
      return;
    }

    emailjs
      .sendForm(
        "service_7uzhm0r", // YOUR_SERVICE_ID
        "template_x2g6v7a", // YOUR_TEMPLATE_ID
        form.current,
        "y_8tLXr9YjarjhSHW" // YOUR_USER_ID
      )
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Message Sent Successfully!");
            form.current.reset(); // Reset the form fields
            setFormData({
              name: "",
              email: "",
              subject: "",
              message: ""
            });
          } else {
            toast.error("Failed to send message!");
          }
        },
        (error) => {
          toast.error("Failed to send message!");
          console.error("EmailJS error:", error);
        }
      );
  };



  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <Toaster position="bottom-center" reverseOrder={false} />

      <div className="contact-area">
        <div className="container">
          <div className="contact-inner-1">
            <img
              className="top_image_bounce animate-img-1"
              src="assets/img/banner/2.png"
              alt="img"
            />
            <img
              className="top_image_bounce animate-img-2"
              src="assets/img/about/6.png"
              alt="img"
            />
            <div className="row">
              <div
                className="col-lg-8"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img className="w-100" src="assets/img/bg/4.jpg" alt="img" />
              </div>
              <div
                className="col-lg-4 wow animated fadeInRight"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div className="section-title mb-0">
                  {/* <h6 className='sub-title'>GET IN TOUCH</h6> */}
                  <h2 className="title">
                    Schedule a <span> Free </span> Consultation
                  </h2>
                  <p className="content">
                    We’re happy to answer any questions you may have and help
                    you determine which of our services best fit your needs.
                  </p>
                  <form className="mt-4" ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" placeholder="Your Phone" />
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Subject"
                            name="subject"
                            id="subject"
                            value={formData.subject}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            placeholder="Message"
                            defaultValue={""}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            id="message"
                          />
                        </div>
                      </div>
                      {/* reCAPTCHA */}
                      <div className="col-12">
                        <ReCAPTCHA
                          sitekey={environment}
                          onChange={handleRecaptchaChange}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-black mt-0 w-100 border-radius-5"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
